/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";

import { Tutorial, tutorials } from "../data/Tutorials";

import { SubmitHandler, useForm } from "react-hook-form";

import { CopyToClipboard } from "react-copy-to-clipboard";

const shareText = encodeURIComponent(
  "Hey! Been using this cool tool that helps me create pretty links. Thought you may like it. It's free."
);
const shareUrl = encodeURIComponent("https://linkdirect.io");

const shareCombined = encodeURIComponent(`${decodeURIComponent(shareUrl)} 
${decodeURIComponent(shareText)}`);

interface Step1FormData {
  urlRequired: string;
}

export default function Create() {
  const [longUrl, setLongUrl] = useState("");

  const [currentTutorial, setCurrentTutorial] = useState<Tutorial | null>();

  const code = useCallback(() => {
    let url = longUrl;
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    const _code: string = `<noscript><meta http-equiv="refresh" content="0;URL=${url}"></noscript><script>window.location.href = "${url}";</script>`;
    return _code;
  }, [longUrl]);

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch,
    formState: { errors },
  } = useForm<Step1FormData>();
  
  const onSubmit: SubmitHandler<Step1FormData> = (data) => {
    console.log(data);
    setLongUrl(data.urlRequired);
    // @ts-ignore
    window?.ConfettiPage?.play();
  };

  const [copied, setCopied] = useState(false);

  return (
    <div className="steps">
      <div className="step">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="step-title">Step 1</h2>
          <p>
            <strong>What link do you want the short link to go to?</strong>
          </p>
          <input type="text" {...register("urlRequired", { required: true })} />
          <div>{errors.urlRequired && <span>This field is required</span>}</div>
          <button type="submit" className="button">
            Generate Code
          </button>
        </form>
      </div>

      {longUrl && (
        <div>
          <div className="step">
            <h2 className="step-title">Step 2</h2>

            <p>
              <strong>Place this code inside your short-linked page:</strong>
            </p>
            <div className="code-wrap">
              <textarea value={code()} readOnly onFocus={(event) => event.target.select()}></textarea>

              <CopyToClipboard text={code()} onCopy={() => setCopied(true)}>
                <button className="button copy">
                  {copied ? "Copied" : "Copy"}
                </button>
              </CopyToClipboard>
            </div>
            {currentTutorial ? (
              <>
                <p>
                  <strong>
                    {capitalizeFirstLetter(currentTutorial.software)} Tutorial
                  </strong>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentTutorial(null);
                    }}
                    className="more-tutorials"
                  >
                    <strong>
                      <i className="fas fa-arrow-left"></i> View all tutorials
                    </strong>
                  </a>
                </p>
                <div className="videoWrapper">
                  <iframe src={currentTutorial.youtubeLink} title="Tutorial Video"></iframe>
                </div>
              </>
            ) : (
              <>
                <p>
                  <strong>Tutorials</strong>
                </p>
                <p>
                  Learn how to add your code to your website using... (click on
                  a logo)
                </p>
                <div className="logos">
                  {tutorials.map((tutorial, key) => (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentTutorial(tutorial);
                      }}
                      key={key}
                    >
                      <img
                        src={`/images/${tutorial.software}@512x.png`}
                        alt={tutorial.software}
                      />
                    </a>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="step">
            <h2 className="step-title">Step 3</h2>
            <p>
              <strong>
                Consider sharing this website with friends and colleges!
              </strong>
            </p>
            <div className="social-icons">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://web.whatsapp.com/send?text=${shareCombined}`}
              >
                <i className="fab fa-whatsapp"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://t.me/share/url?url=${shareUrl}&text=${shareText}`}
              >
                <i className="fab fa-telegram"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/intent/tweet?text=${shareCombined}`}
              >
                <i className="fab fa-twitter"></i>
              </a>
              {/* <a
            target="_blank"
            rel="noreferrer"
            href={`http://pinterest.com/pin/create/button/?url=${shareUrl}&media={URI-encoded URL of the image to pin}&description={optional URI-encoded description}`}
          >
            <i className="fab fa-pinterest"></i>
          </a> */}
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:?&body=${shareCombined}`}
              >
                <i className="fas fa-envelope"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`sms:?&body=${shareCombined}`}
              >
                <i className="fas fa-sms"></i>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
